import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from 'Components/Layout'
import SeoHead from 'Components/SeoHead'
import uiManager from 'Components/Utils/uiManager'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'
import WindowStore from 'WindowStore'
import { valBetween } from 'Components/Utils/helpers'
import WindowActions from 'WindowActions'
import scrollTop from 'simple-scrolltop'

class ComponentsTestCC extends Component {
  constructor(props) {
    super(props)
    this.medias = []
    this.parent = React.createRef()
    const { components } = props.data.allComponentsTestsCcJson.edges[0].node
    this.components = components.map((component) => {
      return {
        type: component.type,
        params: component.params,
        components: component.components
      }
    })
    this.uiManager = new uiManager(this.components, this.onMediaBlockDidLoad)
  }
  componentDidMount() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  }
  componentDidUpdate() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
  handleScroll = () => {
    window.requestAnimationFrame(this.checkForActivity)
  }
  checkForActivity = () => {
    if (this.medias.length === 0) return
    const windowH = WindowStore.Size.h
    const scrollPos = scrollTop()
    this.medias.forEach((media, index) => {
      const topBound = media.top
      const bottomBound = media.top + media.rect.height
      if (scrollPos + windowH > topBound && scrollPos + 50 < bottomBound) {
        const length = (topBound - 50) + (bottomBound - topBound)
        const val = valBetween((scrollPos / length) * 1, 0, 1)
        const totalDuration = media.el.duration
        const currentTime = totalDuration * val
        media.el.currentTime = currentTime
        // console.log(currentTime)
        // media.el.pause()
        // console.log('render', index, scrollPos, length, val)
      }
    })
  }
  setupMedia = () => {
    this.medias = []
    // const images = Array.from(this.parent.current.getElementsByTagName('img'))
    const images = []
    const videos = Array.from(this.parent.current.getElementsByTagName('video'))
    const assets = [...images, ...videos]
    assets.forEach((asset) => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const rect = asset.getBoundingClientRect()
      asset.pause()
      this.medias.push({
        el: asset,
        top: rect.top + scrollTop,
        rect
      })
    })
    // console.log(this.medias)
  }
  onMediaBlockDidLoad = () => {
    this.setupMedia()
  }
  render() {
    const components = this.uiManager.renderComponents()
    return (
      <Layout>
        <SeoHead
          title="Components Test Page"
          path={this.props.location.pathname}
        />
        <div ref={this.parent}>{components}</div>
      </Layout>
    )
  }
}

export const ComponentTestsCCQuery = graphql`
query ComponentTestsCCQuery {
  allComponentsTestsCcJson {
    edges {
      node {
        components {
          type
          params {
            height
          }
          components {
            type
            params {
              center
              type
              soundControl
              src
              srcMobile
              srcRetina
              extraClasses
            }
          }
        }
      }
    }
  }
}
`

export default ComponentsTestCC
